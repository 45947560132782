<template>
    <section>
        <b-table-simple responsive borderless fixed class="table">
            <thead>
                <th><Ellipsis>Data</Ellipsis></th>
                <th><Ellipsis>Entrada</Ellipsis></th>
                <th><Ellipsis>Saída</Ellipsis></th>
                <th><Ellipsis>Saldo final</Ellipsis></th>
                <th><Ellipsis>Pagos</Ellipsis></th>
                <th><Ellipsis>Pendentes</Ellipsis></th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="(date, i) in Object.keys(cashFlow)" :key="date" :class="{ striped: i%2 !== 0, today: isToday(date), future: isFuture(date) }">
                    <td :class="{ 'today-tag': isToday(date) }">
                        {{ parseDate(date) }}
                    </td>
                    <td>
                        <div class="green">
                            <Positive class="positive" /> {{ parseNumberToMoney(getEntrances(cashFlow[date])) }}
                        </div>
                    </td>
                    <td>
                        <div class="red">
                            <Negative class="negative" /> {{ parseNumberToMoney(getOutputs(cashFlow[date])) }}
                        </div>
                    </td>
                    <td>
                        <span class="total">{{ parseNumberToMoney(getFinalBalance(cashFlow[date])) }}</span>
                    </td>
                    <td>
                        {{ normalizeProductNumber(getMovementsCount(cashFlow[date], ['paid_out']), 2) }}
                    </td>
                    <td>
                        {{ normalizeProductNumber(getMovementsCount(cashFlow[date], ['expired','pending']), 2) }}
                    </td>
                    <td class="right">
                        <span class="details" @click="onEdit(date)">Ver detalhes</span>
                    </td>
                </tr>
                <tr class="blue">
                    <td><span class="total">Total</span></td>
                    <td>
                        <div class="green">
                            <Positive class="positive" /> 
                            {{ parseNumberToMoney(getTotalEntrances()) }}
                        </div>
                    </td>
                    <td>
                        <div class="red">
                            <Negative class="negative" />{{ parseNumberToMoney(getTotalOutputs()) }}
                        </div>
                    </td>
                    <td><span class="total">{{ parseNumberToMoney(getTotalFinalBalance()) }}</span></td>
                    <td><span class="total">{{ normalizeProductNumber(getMovementsCountTotal('paid_out'), 2) }}</span></td>
                    <td><span class="total">{{ normalizeProductNumber(getMovementsCountTotal(['expired','pending']), 2) }}</span></td>
                    <td></td>
                </tr>
            </tbody>
        </b-table-simple>
    </section>
  
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { normalizeProductNumber } from '@/utils/product-helper'

export default {
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Positive: () => import('@/assets/icons/positive.svg'),
        Negative: () => import('@/assets/icons/negative.svg'),
    },
    props: {
        cashFlow: Object,
    },
    methods: {
        parseNumberToMoney,
        normalizeProductNumber,
        parseDate(date) {
            if (this.isToday(date)) return this.moment(date).format('DD/MM/YYYY [(hoje)]')
            return this.moment(date).format('DD/MM/YYYY [(]ddd[)]')
        },
        isToday(date) {
            return this.moment(date).isSame(this.moment(), 'day')
        },
        isFuture(date) {
            return this.moment(date).isAfter(this.moment())
        },
        getBeginBalance(installments) {
            if (!installments?.length) return 0
            return installments.reduce((acc, curr) => curr.type === 'entrance' ? (acc + curr.amount) : (acc - curr.amount), 0)
        },
        getEntrances(installments) {
            if (!installments?.length) return 0
            return installments.filter(installment => installment.payment_method_name !== 'Crédito do paciente').reduce((acc, curr) => curr.type === 'entrance' ? (acc + curr.amount) : acc, 0)
        },
        getTotalEntrances() {
            return Object.keys(this.cashFlow).reduce((acc, curr) => acc + this.getEntrances(this.cashFlow[curr]), 0)
        },
        getOutputs(installments) {
            if (!installments?.length) return 0
            return installments.reduce((acc, curr) => curr.type === 'output' ? (acc + curr.amount) : acc, 0)
        },
        getTotalOutputs() {
            return Object.keys(this.cashFlow).reduce((acc, curr) => acc + this.getOutputs(this.cashFlow[curr]), 0)
        },
        getFinalBalance(installments) {
            if (!installments?.length) return 0
            return installments.reduce((acc, curr) => curr.type === 'entrance' ? (acc + curr.amount) : (acc - curr.amount), 0)
        },
        getTotalFinalBalance() {
            return Object.keys(this.cashFlow).reduce((acc, curr) => acc + this.getFinalBalance(this.cashFlow[curr]), 0)
        },
        getMovementsCount(installments, status) {
            return installments.filter(el => status.includes(el.status)).length
        },
        getMovementsCountTotal(status) {
            return Object.keys(this.cashFlow).reduce((acc, curr) => acc + this.getMovementsCount(this.cashFlow[curr], status), 0)
        },
        onEdit(date) {
            this.$emit('onEdit', date)
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.total {
    font-weight: 600;
    color: var(--dark-blue);
}
.right {
    text-align: right !important;
}
.details {
    color: var(--blue-700);
    font-weight: 700;
    cursor: pointer;
}
.green {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    color: #097C53;
}
.red {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    color: var(--states-error);
}
.blue {
    background-color: var(--blue-100);
}
.striped {
    background-color: #F8F8F8;
}
.today-tag {
    border-left: 8px solid var(--orange);
}
.today {
    background-color: #FFF3E1 !important;
}
.future {
    background-color: #FFFFFF !important;
    td {
        color: var(--type-placeholder) !important;
        span, div {
            color: var(--type-placeholder) !important;
            .positive {
                fill: var(--type-placeholder) !important;
            }
            .negative {
                stroke: var(--type-placeholder) !important;
            }
        }
    }
}
</style>